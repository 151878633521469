/*########### Functional ###########*/
import React, {useState, useEffect, useRef} from "react";
import GoogleFontLoader from 'react-google-font-loader';
import NoSsr from '@material-ui/core/NoSsr';
import { BrowserRouter, Route, Switch, Link, useLocation } from 'react-router-dom';

/*########### Views ###########*/
//import AboutView from "./AboutView";
//import MainView from "./MainView";
//import AppView from "./AppView";
//import AchievementsView from "./AchievementsView";
//import PricingView from "./PricingView";

/*########### Custom ###########*/
import "./components/layout/App.css";

const App = () => {
  const AppView = React.lazy(() => import('./AppView'));
  const AchievementsView = React.lazy(() => import('./AchievementsView'));
  const AboutView = React.lazy(() => import('./AboutView'));
  const PricingView = React.lazy(() => import('./PricingView'));
  const MainView = React.lazy(() => import('./MainView'));

  return (
    <>
        <NoSsr>
            <GoogleFontLoader
                fonts={[
                { font: 'Scheherazade New', weights: [400] },
                { font: 'Montserrat', weights: [100] }
                ]} />
        </NoSsr>

        {/* Wrapper for routing */}
        <React.Suspense fallback={<span></span>}>
        <BrowserRouter>
            <Switch>
                {/* Page - App */}
                <Route path="/app" component={AppView} />

                {/* Page - About */}
                <Route path="/about" component={AboutView} />

                {/* Page - Achievements */}
                <Route path="/achievements" component={AchievementsView} />

                {/* Page - Pricing */}
                <Route path="/pricing" component={PricingView} />

                {/* Page - Main */}
                <Route path="/" component={MainView} />
            </Switch>
        </BrowserRouter>
        </React.Suspense>
    </>
  );
}

export default App;
